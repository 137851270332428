export default {
  common: {
    accountSetting: "帳號設定",
    passwordSetting: "密碼設定",
    pi: "請輸入{0}",
    ps: "請選擇{0}",
    and: "和",
    account: "帳號",
    verificationCode: "驗證碼",
    password: "密碼",
    currentPassword: "當前密碼",
    goLogin: "已有帳號,去登錄?",
    confirm: "確定",
    confirm2: "確認",
    cancel: "取消",
    confirmPassword: "確認密碼",
    confirmSettings: "確認設定",
    newPassowrd: "新密碼",
    userName: "用戶名稱",
    dataCenter: "資料中心",
    appDownload: "移動端APP下載頁面",
    mail: "信箱",
    europe: "歐洲",
    theUnitedStates: "美國",
    australia: "澳大利亞",
    china: "中國",
    india: "印度",
    phone: "手機號碼",
    len: "{0}長度至少為{1}個字元",
    registerSuccessConfirm: "註冊成功，是否前往登錄?",
    registerSuccessPLogin: "註冊成功，請登錄!",
    confirmDiff: "密碼輸入不一致",
    newPwdSame: "所設置的新密碼不可與當前密碼一致",
    newPwdDiff: "密碼輸入不一致",
    passwordFormatError: "8-16位元，至少包含數字、字母或符號中的兩項或以上",
    phoneAndMailFormatError: "手機號碼 / 信箱格式錯誤",
    phoneFormatError: "手機號碼格式錯誤",
    mailFormatError: "信箱格式錯誤",
    successfully: "成功",
    close: "關閉",
    operation: "操作",
    query: "查詢",
    pageTotal: "共 {0} 條",
    pageTotal2: "共 {0} 台",
    submit: "提交",
    min: "請輸入不少於{0}個字元",
    max: "請輸入少於{0}個字元",
    minMaxLimit: "請輸入{0}-{1}個字元",
    basic: "基本",
    tag: "TAG",
    device: "設備",
    alarm: "警報",
    screen: "組態畫面",
    loginSuccessfully: "帳號登入成功!",
    logoutSuccessfully: "帳號登出成功!",
    read: "讀取",
    write: "寫入",
    readWrite: "讀寫",
    edit: "編輯",
    delete: "刪除",
    tip: "提示",
    updateSuccessfully: "更新成功!",
    alarmLevel1: "非常緊急",
    alarmLevel2: "普通",
    alarmLevel3: "比較緊急",
    online: "線上",
    offline: "離線",
    logout: "帳號登出",
    settings: "設置",
    colorScheme: "配色主題",
    menuScheme: "選單主題",
    lightMode: "日間模式",
    darkMode: "夜間模式",
    light: "淺色",
    dark: "深色",
    operationSuccessfully: "操作成功!",
    valName: "變數名稱",
    create: "建立",
    yes: "是",
    no: "否",
    getUserInfoFaild: "獲取使用者資訊失敗，請重新登入!",
    switchToNew: "請完善使用者基本資訊",
    switchToNewMsg: "完善使用者資訊成功!",
    resetPassword: "重置密碼",
    permission: "權限",
    roleName: "角色名稱",
    unbindCurrDevice: "確定刪除設備",
    unbindCurrDeviceMemo: "刪除該設備後，監控該設備產生的資料將被刪除且不可恢復",
    changePasswordSuccessfully: "修改密碼成功，請重新登入!",
    changeProfileSuccessfully: "修改使用者資訊成功!",
    noData: "暫無數據",
    backToLogin: "返回登入頁面",
    mailResultMemo1: "感謝註冊！啟動郵件已發送至您的信箱：",
    mailResultMemo2: "請在48小時內登入信箱，按照信箱中的提示完成啟動",
    mailResultMemo3: "沒有收到驗證碼怎麼辦?",
    mailResultMemo4: "1、檢查是否存在於廣告郵件、垃圾郵件中",
    mailResultMemo5: "2、確認未收到郵件，請嘗試",
    reSend: "重新發送",
    activeMail: "啟動郵件",
    moveTo: "移動到...",
    groupPickerMemo1: "可移動到已建立的任一組級中",
    groupPickerMemo2: "請選擇要放置的組級位置",
    deleteTemplate: "刪除模板",
    deleteTemplateConfirm: "是否刪除模板",
    activeResult: "啟動結果",
    dataCenterPickerMemo1: "您的設備資料將存儲於所選的資料中心，請謹慎選擇",
    templateLimit: "您的模板個數已達上限",

    tiao: "條"
  },
  login: {
    loginPlatform: "登入DIACloud雲平台",
    account: "帳號",
    password: "密碼",
    rememberPassword: "記住密碼",
    phoneAndMailAndUsername: "手機號碼 / 信箱",
    readAndAgree: "我已閱讀並同意",
    serviceAgreement: "服務協定",
    privacyAgreement: "隱私政策",
    registerNow: "立即註冊",
    forgotPassword: "忘記密碼",
    login: "登入",
    goLogin: "前往登入",
    agreeMemo: "請閱讀並同意服務協定與隱私政策",
    cookieTips1: "本網站使用cookies",
    cookieTips2: "我們使用cookies來改善您在網站上的體驗，我們尊重您的選擇，並致力於為您提供安全的流覽體驗。",
    reject: "拒絕",
    accept: "接受"
  },
  register: {
    registerWay: "註冊方式",
    register: "註冊",
    phoneRegistration: "手機號碼註冊",
    mailRegistration: "信箱註冊",
    submit: "提交註冊",
    getVarifyCode: "獲取驗證碼",
    second: "秒",
    sendToMuch: "發送次數過多",
    contactMemo: "用於消息設備-接收管道資訊，不用於帳號登入"
  },
  dashboard: {
    dashboard: "數據看板",
    alarm: "警報",
    collectionEmpty: "暫無收藏設備",
    goto: "前往",
    download: "下載",
    favoriteDevice: "收藏設備",
    deviceOverview: "設備資訊",
    hmiScreen: "HMI畫面",
    config: "組態畫面",
    thumbnail: "縮略圖",
    viewDcreen: "查看組態畫面",
    editScreen: "編輯組態畫面",
    configScreen: "組態畫面",
    hmiBind: "HMI綁定",
    billingDetail: "計費詳情",
    basicInfo: "基本資訊",
    configUpdate: "組態更新",
    hmiScreenSN: "HMI設備SN",
    hmiGatewaySN: "HMI閘道SN",
    deviceName: "設備名稱",
    deviceSN: "設備SN",
    deviceModel: "設備型號",
    deviceGroup: "設備分組",
    relateTemplate: "關聯模板",
    deviceAddress: "設備位址",
    remark: "備註",
    configCreate: "組態建立",
    configModify: "組態修改",
    maskSuccessfully: "收藏成功!",
    removeMaskSuccessfully: "取消收藏成功!",
    editModelMemo: "當前資訊未保存，切換標注將會放棄所有修改資料?",
    notAssociated: "未關聯",
    editScreenMsg1: "請使用 DIAWebDesigner 編輯畫面",
    editScreenMsg2: "如果您的電腦未安裝 DIAWebDesigner",
    please: "請"
  },
  device: {
    unBindDevice: "刪除設備",
    unBindDeviceOk: "刪除設備成功",
    deviceManage: "設備管理",
    deviceList: "設備清單",
    templateList: "模板清單",
    deviceGroup: "設備分組",
    tunnel: "Tunnel狀態",
    groupSameName: "分組名稱重複",
    rename: "重新命名",
    addGroup: "添加分組",
    addChildGroup: "添加子分組",
    delete: "刪除",
    deleteSucccessful: "刪除{0}成功!",
    groupName: "分組名稱",
    defaultGroup: "默認分組",
    setDefaultGroup: "設為默認分組",
    templateName: "模板名稱",
    hmiModel: "HMI設備型號",
    deviceCount: "設備數量",
    publishTime: "創建時間",
    queryNamePh: "模板名稱",
    queryModelPh: "HMI設備型號",
    addTemplate: "添加模板",
    copyTemplate: "複製模板",
    deviceName: "設備名稱",
    deviceSN: "設備SN",
    description: "客戶名稱",
    deviceModel: "設備型號",
    hmiScreenModel: "HMI設備型號",
    cloneTemplate: "複製模板",
    remark: "備註",
    templateInfo: "模板資訊",
    hmiScreen: "HMI設備",
    fw: "韌體",
    recipe: "配方",
    versionNumber: "版本號",
    createTime: "建立時間",
    configurationState: "組態",
    configurationStateCreateTime: "組態建立時間",
    configurationStatemodifyTime: "組態修改時間",
    tagName: "TAG名稱",
    dataType: "資料類型",
    addrType: "地址類型",
    address: "地址",
    rwType: "讀寫類型",
    scanTime: "掃描時間",
    saveHistroy: "歷史存檔",
    devTemProName: "設備名稱 / 關聯模板 / 關聯項目",
    hmiDongleState: "HMI閘道狀態",
    hmiGraph: "HMI畫面",
    hmiDongleAsync: "HMI與雲端同步",
    deviceTag: "設備標注",
    tagEmpty1: "暫無標注",
    createTag: "建立標注",
    editTag: "編輯標注",
    deleteTag: "刪除標注",
    addTag: "添加標注",
    tagNumLimit: "標注上限50個",
    checkTagNumLimit: "選中標注上限10個",
    createAndSearchTag: "建立 / 搜索標注",
    addDevice: "添加設備",
    syncState1: "已同步",
    syncState0: "待同步",
    cloudLastVer: "雲端最新版本",
    hmiThisVer: "HMI版本",
    asyncTooltipTitle: "HMI本地與雲端配置同步情況 (請前往HMI-系統更新，進行同步)",
    labelOver: "標注長度限制1-50個字元",
    labelSame: "標注名稱已存在",
    labelNumLimit: "標注數量達到50個上限",
    deviceChangeGroup: "設備移組",
    changeGroupSuccessfully: "移組成功!",
    addModel: "新增型號",
    templateDesign: "組態設計",
    unbindLabel: "刪除標注",
    unbindLabelConfirm: "刪除該標注會同步刪除其他設備已綁定的同名標注，是否確定刪除?",
    templateDesignConfirm: "是否編輯設備已加入的模板{0}?",
    projectlist: "項目列表",
    costomName: "客戶名稱",
    groupCantRepeat: "分組名稱不可重複",

    projectName: "專案名稱",
    deleteProject: "刪除專案",
    deleteProjectConfirm: "是否刪除專案{0}",
    addProject: "增加專案",
    addProject2: "新增專案",
    projectTips: "該專案已過期，請開通Cloud Application計費卡",
    projectDetail: "專案訊息",
    removeDev: "移除設備",
    removeDevConfirm: "是否移除設備{0}",
    relatDev: "關聯設備",
    viewTag: "查看TAG",
    remove: "移除",
    assignGroupPm: "請分配設備分組權限",
    project: "專案",
    assignProjectPm: "請分配專案列表權限",
    usedTemplate: "模板引用",
    usedProject: "專案引用"
  },
  template: {
    defaultValue: "預設值",
    description: "描述",
    used: "是否被引用",
    templateMemo: "模板介紹",
    bindTemplateTip: "確定綁定模板",
    templateLockTip1: "該模板已過期，請啟動Cloud Application計費卡，充值模板數量",
    templateLockTip2:
      "TIP: 啟動Cloud Application計費卡後，從最近建立的模板開始恢復，請控制好您的模板本總數，以免您需要的模板無法恢復使用"
  },
  message: {
    messageCenter: "消息中心",
    alarm: "警報",
    alarmMag: "警報記錄",
    system: "系統公告",
    billingMsg: "計費消息",
    billingMsgType1: "Remote Access到期提醒",
    billingMsgType2: "Cloud Application到期提醒",
    billingMsgType3: "設備TAG到期提醒",
    billingMsgType4: "設備流量不足提醒",
    billingMsgType5: "帳號TAG不足提醒",
    billingMsgType6: "邀請通知",
    billingMsgType7: "取消邀請提醒",
    billingMsgType8: "移出團隊提醒",
    billingMsgType9: "主帳號轉讓通知",
    readAllTip: "已全部標記已讀",
    total: "全部",
    unread: "未讀",
    msgType: "消息類型",
    startDate: "選擇開始時間",
    endDate: "選擇結束時間",
    readAll: "全部已讀",
    billing: "計費",
    notifications: "公告",
    warnings: "警告",
    noUnread: "暫無消息",
    noUnreadToday: "暫無未讀消息",
    goAllMsg: "查看全部",
    monthTraffic: "本月消耗流量",
    open: "已開啟",
    close: "已關閉",
    continued: "繼續使用",
    closeTraffic: "關閉流量",
    todayNews: "今日消息 ({0}條)",
    trafficTips1: "是否確認開啟流量?",
    trafficTips2: "開啟後本月不可再關閉流量，請謹慎操作",
    trafficTips3: "若流量不足，仍需繼續使用，請在【計費管理-我的帳戶】中充值Remote Acces計費卡並分配給本設備使用",
    trafficTips4: "本月已消耗12G流量，是否繼續使用?",
    trafficTips5: "關閉流量只針對本月，下月可正常使用",
    trafficTips6: "關閉後如需繼續使用流量，可在【設備管理-設備清單】中開啟",
    trafficTips7: "本月流量已關閉",
    trafficTips8: "如需繼續使用請在【設備清單-設備詳情】開啟流量"
  },

  system: {
    name: "名稱",
    system: "系統管理",
    role: "角色管理",
    user: "用戶管理",
    personCenter: "個人中心",
    safeZone: "安全區",
    dashboard: "數據看板",
    device: "設備管理",
    billing: "計費管理",
    message: "消息中心",
    devicelist: "設備清單",
    templatelist: "模板清單",
    devicegroup: "設備分組",
    projectlist: "專案列表",
    myAccount: "我的帳戶",
    rechargeRecord: "充值記錄",
    msgBilling: "計費消息",
    relatProject: "關聯專案",

    DIACom: "DIACom",
    DIAComMsg: "開啟狀態時，在DIACom中可見到設備",

    "dashboard-view": "訪問",
    "devicelist-view": "訪問",
    "devicelist-bind": "綁定設備",
    "devicelist-edit": "編輯",
    "devicelist-delete": "刪除",
    "devicelist-hmi": "HMI畫面",
    "devicelist-config": "組態畫面",
    "templatelist-view": "訪問",
    "templatelist-add": "新增模板",
    "templatelist-copy": "複製模板",
    "templatelist-edit": "新增/編輯模板",
    "templatelist-delete": "刪除",
    "templatelist-data-pm": "分配權限",
    "devicegroup-view": "訪問",
    "devicegroup-add": "新增分組",
    "devicegroup-edit": "編輯",
    "devicegroup-delete": "刪除",
    "devicegroup-data-pm": "分配權限",
    "projectlist-view": "訪問",
    "projectlist-add": "新增項目",
    "projectlist-edit": "編輯",
    "projectlist-delete": "刪除",
    "projectlist-data-pm": "分配權限",
    "myAccount-view": "訪問",
    "myAccount-ca-charge": "Cloud Application充值",
    "myAccount-ca-allcoate": "TAG分配",
    "myAccount-ra-charge": "Remote Access充值",
    "myAccount-ra-allcoate": "計費卡分配",
    "rechargeRecord-view": "訪問",
    "msgBilling-view": "訪問",
    "user-view": "訪問",
    "user-invite": "邀請用戶",
    "user-edit": "變更角色",
    "user-delete": "刪除",
    "user-data-pm": "數據權限",
    "role-view": "訪問",
    "role-add": "新增角色",
    "role-edit": "編輯",
    "role-delete": "刪除",
    "safeZone-view": "訪問",
    "safeZone-add": "新增安全區",
    "safeZone-edit": "編輯",
    "safeZone-delete": "刪除",
    mainAccount: "主帳號",
    subAccount: "子帳號",
    teamName: "團隊名稱",
    team: "團隊",
    modifyTeamName: "請於右上角用戶下拉欄裡修改團隊名稱",
    No: "序號",
    roleName: "角色名稱",
    cannotDelete: "無法刪除{0}",
    deleteRoleFirst: "請先刪除該角色下所有用戶，方可刪除該角色!",
    isDelete: "是否刪除{0}?",
    addsafeZone: "新增安全區",
    addRole: "新增角色",
    selectPmSettings: "請選擇權限設置",
    enterRoleName: "請輸入角色名稱",
    editRole: "編輯角色",
    inviteAcceptTime: "邀請接受時間",
    multipleSelection: "可多選",
    Pmettings: "權限設置",
    menuBar: "功能表列",
    selectAll: "全選",
    operationPm: "操作權限",
    dataPm: "數據權限",
    safeZoneInUse: "該安全區有以下角色正在使用，無法刪除",
    inputAlphanumeric: "請輸入[字母+數位]",
    inputAlphaNumericOnly: "只能輸入[字母+數位]",
    editsafeZone: "編輯安全區",
    removeFromTeam: "是否將{0}從團隊中刪除",
    cancelPendingInvitations: "刪除之後，該用戶已發送但未被接受的邀請將會被取消",
    accountUsername: "帳號/用戶名",
    inviteUser: "邀請用戶",
    emailAccount: "信箱帳號",
    registerAccount: "該帳號未註冊，請先完成帳號註冊",
    notCurrentDataCenterAccount: "非當前資料中心帳號",
    joinedOtherTeams: "該帳號已加入其他團隊",
    inCurrentTeam: "該帳號已在當前團隊中",
    notInCurrentTeam: "該帳號不在當前團隊內",
    roleChange: "角色變更",
    announcement: "公告",
    startClearData: "開始清空資料",
    acceptAndReLogin: "接受並重新登入",
    rejectInvite: "拒絕邀請",
    inviteNotification: "邀請通知",
    inviteJoinTeam1: "{0}邀請您加入{1}團隊",
    dataOnAccount: "若您帳號上存在設備、模板、計費卡等資訊，建議您註冊新的帳號加入團隊",
    unbindDevices: "帳號下如有資料，需手動清空資料（解綁設備、刪除模板）後方可加入團隊，且資料不可恢復",
    clearDataNotice: "計費卡以及充值記錄不會被清除，但個人充值卡無法帶入到團隊帳號下",
    clearDataEffect: "清空資料之後，不影響已分配出去的TAG和流量",
    successJoinTeam: "成功加入團隊後，帳號權限將由主帳號分配",
    reInviteIfRejected: "拒絕邀請後，如需再次加入該團隊，可請邀請人重新發起邀請",
    unbindDevice: "解綁設備",
    confirmDeleteDevice1: "確定刪除設備{0}?",
    confirmDeleteDevice2: "是否刪除設備{0}?",
    deleteDeviceWarning: "刪除該設備後，監控該設備產生的資料將被刪除且不可恢復",
    invitePage: "邀請頁面",
    nextStep: "下一步",
    inviteCancelled1: "該邀請已被取消",
    youRejectedInvite: "您已拒絕該邀請",
    reInviteIfNeeded: "如需加入該團隊，可請邀請人重新發起邀請",
    alreadyInTeam: "您已在該團隊了",
    inviteExpired: "該邀請已作廢",
    reminder: "提醒",
    viewInvites: "立即查看",
    pendingInvites: "您有多條邀請通知未處理，請及時查看處理",
    inviteJoinTeam2: "{0}邀請您加入{1}團隊，請確認是否接受邀請",
    inviteCancelled2: "{0}已取消邀請您加入{1}團隊",
    removedFromTeam: "您已被移出{0}團隊",
    inviteBecomeMainAccount: "{0}邀請您成為{1}團隊的主帳號，請確認是否接受?",
    editTeamName: "編輯團隊名稱",
    teamNamePlaceholder: "團隊名稱",
    inviteSent: "邀請通知已發送，等待對方接受邀請！",
    assignRoleListPm: "請分配角色列表權限",
    assignPm: "分配權限",
    contactAdminForPm: "請聯繫管理員分配權限",
    close: "關閉",
    pendingInvitesAgain: "您有多條邀請通知未處理，請及時查看處理",
    userAccount: "用戶帳號",
    systemAdministrator: "系統管理員",
    allPm: "全部",
    platformAllPm: "擁有平台所有權限",
    setRolePm: "請設置角色權限",
    enterRoleNamePlaceholder: "請輸入角色名稱",
    maxTenCharacters: "最多輸入10個字元",
    assignTemplateListPm: "請分配模板清單權限",
    addDataPm: "新增數據權限",
    multipleSelection2: "可多選",
    removePm: "移除權限",
    continueSending: "繼續發送",
    sendBillingMessages: "當前未設置團隊名稱，是否繼續發送計費消息?",
    sameDataCenterAccounts: "只能邀請同一資料中心下的帳號",
    access: "訪問",
    cloudApplicationTopUp: "Cloud Application充值",
    remoteAccessTopUp: "Remote Access充值",
    noGroups: "暫無分組",
    contactAdminForPm2: "請聯繫系統管理員分配權限",
    uniqueTeamGroups: "團隊中分組不可重複",
    loginWebToAcceptInvite: "請登入web端接受邀請"
  },
  billing: {
    billingManage: "計費管理",
    myAccount: "我的帳戶",
    billingOverview: "計費總覽",
    rechargeRecords: "充值記錄",
    cardType: "計費卡類型",
    cardNumber: "卡號",
    cardName: "計費卡名",
    cloudAppCard: "Cloud Application",
    remoteAccessCard: "Remote Access",
    activeOp1: "輸入卡號後 8碼",
    activeOp2: "刮開圖層，輸入卡片密碼",
    activeOp3: "打開計費卡背面",
    activating: "生效中",
    Outdated: "已失效",
    noActivated: "未啟動",
    activeDate: "啟動日期",
    selectActiveDate: "選擇啟動日期",
    status: "狀態",
    validityDate: "有效期",
    TagVal: "TAG有效期",
    opAccount: "操作帳號",
    deviceLevel: "設備當前等級",
    deviceNowTag: "設備當前TAG（已使用/總數 • 個）",
    allocateDevice: "分配設備",
    allocateDate: "分配時間",
    selectAllocateDate: "選擇分配時間",
    allocateLevel: "分配等級",
    allocateTagNum: "分配TAG數",
    allocateTagStatus: "分配TAG狀態",
    allocateTagVal: "分配TAG有效期",
    allocateCard: "分配計費卡",
    sTagNum: "剩餘TAG",
    sTemplateNum: "剩餘模板",
    platformGifts: "平台贈送",
    rechargeNow: "立即充值",
    total: "總數",
    unit: "個",
    unit2: "張",
    used: "已使用",
    left: "剩餘",
    tagAllocate: "TAG分配",
    cardAllocate: "計費卡分配",
    allocateTips1: "請將{0}分配給設備使用",
    allocateTips2: "請注意{0}有效期，以免未使用的{0}過期，造成資源浪費",
    allocateTips3: "分配給設備的{0}將始終使用於被分配設備，直到{0}過期",
    viewAll: "查看詳情",
    template: "模板",
    cardUseAla: "計費卡使用分析",
    num: "數量",
    notSet: "暫不分配等級",
    keepNowLevel: "保持設備當前等級",
    allocateTips: "TAG數量 {0} 個 / 採集頻率 {1} 分鐘",
    level: "等級",
    CollFrequency: "採集頻率",
    Minutes: "分鐘",
    tagNumber: "TAG數量",
    month: "月",
    year: "年",
    deviceDetail: "設備詳情",
    returnTag: "返回TAG分配",
    returnDetail: "返回TAG詳情",
    deviceUnbind: "設備已解綁本帳號",
    notThisAccount: "非本帳號充值",
    levelDesc: "等級說明",
    allocateTips4: "● 平台贈送的TAG分配等級只能選擇{0}",
    allocateTips5: "● TAG分配規則 (採集頻率按當前等級為准)",
    allocateTips6: "分配等級僅支援升級，不支持降級",
    allocateTips7: "設備綁定帳號改變，之前綁定帳號給設備分配的未過期{0}，將始終跟著設備",
    allocateTips8: "未失效流量將始終跟著設備",
    optionalRange: "可選範圍",
    unallocated: "待分配",
    nowAllocateTagNum: "當前可分配TAG數量",
    avaleBalance: "可用餘額",
    CurrAllocate: "本次分配",
    allCardNum: "總張數",
    activedCardNum: "生效中",
    deactivedCardNum: "已失效",
    allocateSuccess: "分配成功",
    tagStatus: "TAG 狀態",
    allocatedTagStatus: "分配TAG狀態",
    traffic: "流量",
    trafficCard: "流量卡",
    trafficTips: "流量使用提醒",
    billcardPackage: "計費卡套餐",
    billcardActive: "計費卡啟動",
    dataStorage: "資料儲存",
    validityPeriod: "有效期",
    dataCollFrequency: "資料獲取頻率",
    templateNum: "模板數量",
    pleaseInput: "請輸入",
    pleaseSelect: "請選擇",
    numTip: "請輸入卡片號碼",
    passwordTip: "請輸入卡片密碼",
    InputEnAndNum8: "請輸入8碼英文與數字",
    InputEnAndNum16: "請輸入8碼英文與數字",
    activedTips: "溫馨提示",
    activedTips1: "計費卡成功啟動後立即生效，有效期1年",
    activedTips2: "請在有效期內使用卡內套餐",
    cardNum: "卡片號碼",
    cardPassword: "卡片密碼",
    InputPassword: "請輸入8碼卡片密碼",
    InputNum: "請輸入卡片號碼後8碼",
    successTip: "您的計費卡已成功啟動",
    cardValidityDate: "計費卡有效期",
    successTip2: "請在有效期內使用卡內套餐",
    failTip: "你的計費卡啟動失敗",
    failReasonTip: "啟動失敗原因",
    next: "下一步",
    activeNow: "立即啟動",
    gotIt: "知道了",
    actived: "生效中",
    deActived: "已失效",
    tagMessage: "TAG餘額不足，無法繼續分配，請使用Cloud Application計費卡充值。",
    action: "立即充值",
    know: "我知道了",
    description1: "點擊立即充值後，自動確認本頁面設置",
    cloudApplication: "Cloud Application",
    remoteAccess: "Remote Access",
    vncTips1: "VNC使用中，該功能會根據實際使用情況扣除流量，請合理使用！",
    disabledTemplates: "當前有{0}個模板TAG無法使用",
    tagInfo: "TAG 訊息",
    requirements1: "要保證所有模板TAG都可使用",
    requirements2: "需滿足設備TAG數≥模板TAG數",
    deviceTagCount: "設備可用TAG數",
    templateTagCount: "模板TAG數",
    vncTips2: "查看HMI畫面，需根據實際使用扣除對應流量，請確認是否繼續?",
    vncEnd: "VNC 使用結束",
    timeLimitReached: "您設置了VNC使用時長上限，時間已到，VNC自動關閉。",
    vncTimeSetting: "HMI畫面觀看時長設置",
    instruction: "當達到開啟時長上限，將自動關閉VNC服務",
    tagAction: "設置時長上限",
    trafficTips1: "該設備流量不足，如需繼續使用，請使用Remote Access 計費卡充值。",
    trafficTips2: "目前設備流量不足，請避免再使用流量相關傳輸服務。",
    trafficTips3: "設備使用流量統計，請前往DIACloud 雲平台【計費管理-帳單】查看",
    trafficTips4: "該設備已被刪除",
    trafficTips5: "VNC使用結束，該設備流量不足",
    selectCardType: "請選擇卡片規格",
    trafficTips6: "未分配的Remote Access，可在{0}進行分配設備",
    myCount: "我的帳戶-計費卡分配",
    activate: "啟動",
    trafficTips7: "卡內套餐需分配到設備後才能使用，請在有效期內使用該套餐",
    notAllocate: "暫不分配",
    billingCard: "計費卡",
    trafficTips8: "設備一經分配不可修改",
    trafficTips9: "請謹慎操作",
    trafficTips10: "設備優先使用即將到期的流量卡",
    confirmAllocationTips: "是否確認把該套餐分配給{0}？",
    confirmAllocation: "確認分配",
    trafficPeriod: "流量有效期",
    billingCardTraffic: "計費卡流量",
    trafficTotal: "總流量",
    trafficUsed: "已使用流量",
    trafficLeft: "剩餘流量",
    trafficStatus: "剩餘流量狀態",
    trafficLeftCard: "卡剩餘流量",
    trafficLeftDevice: "設備剩餘流量",
    billingCardUsage: "計費卡使用分析",
    trafficStatistics: "未失效的流量統計",
    trafficsLeftValid: "未失效的剩餘流量",
    trafficsLeftTotal: "未失效的總流量",
    selectDateActive: "選擇啟動日期",
    selectDateUsedMin: "選擇使用日期",
    selectDateUsedMax: "選擇使用日期",
    DateofUse: "使用日期",
    trafficUsed2: "使用流量",
    trafficUsage: "流量消耗詳情",
    freeDongle: "Dongle贈送",
    freeHMI: "人機贈送"
  },

  alarm: {
    devOrAlarmName: "設備名稱/警報名稱",
    alarmName: "警報名稱",
    alarmGroup: "警報分組",
    alarmLevel: "警報等級",
    alarmType: "警報類型",
    tagName: "TAG名稱",
    alarmValue: "警報值",
    alarmRule: "偏差值",
    alarmContent: "警報內容",
    alarmEnable: "警報開關",
    alarmGroupMange: "管理警報分組",
    alarmCondition: "警報條件",
    alarmState: "警報狀態",
    alarmTime: "警報時間",
    responseTime: "確認時間",
    recoveryTime: "恢復時間",
    minor: "輕微",
    mild: "較輕",
    moderate: "一般",
    severe: "較重",
    critical: "嚴重",
    HighHigh: "較高",
    High: "高",
    Low: "低",
    LowLow: "較低",
    LargeDeviation: "顯著偏差",
    SmallDeviation: "輕微偏差",
    off: "關",
    on: "開",
    alarm: "報警",
    response: "確認",
    recovery: "恢復",
    selectTag: "選擇TAG",
    errTips1: "{0}未填寫",
    errTips2: "{0}不存在",
    errTips3: "警報類型為{0}的TAG類型不可以是String和Bool",
    errTips4: "警報類型為{0}的TAG類型只能是Bool",
    addAlarm: "新增警報",
    editAlarm: "修改警報",
    when: "當",
    state: "狀態",
    errRes: "失敗原因",
    import: "匯入",
    export: "匯出",
    importErr: "數據匯入失敗",
    errMsg1: "下載錯誤報告並按照提示修改訊息後再次上傳",
    downloadErr: "下載錯誤報告",
    clickUpload: "點擊上傳",
    importRule: "匯入規則",
    importMsg: "請按照模板格式填寫信息",
    importMsg2: "將文件拖曳到此處，或",
    importMsg3: "支援xls、xlsx格式",
    importMsg4: "單次最多支援匯入300條數據",
    downloadTem: "下載模板",
    importComplate: "數據匯入完成",
    fileName: "檔案名稱",
    dataNum: "數據總量",
    importOK: "成功匯入",
    importTime: "匯入時間",
    selectToRes: "勾選確認",
    uploadAgain: "重新上傳",
    goList: "返回列表",
    selectAlarmType: "請先選擇警報類型",
    typeErr: "上傳的文件格式不正確，請上傳Excel文件",
    goAlarmMsg: "查看全部並處理警報"
  },

  ERR_FR_TOO_MANY_REDIRECTS: "太多重定向",
  ERR_BAD_OPTION_VALUE: "無效的參數值",
  ERR_BAD_OPTION: "無效的參數",
  ERR_NETWORK: "網路錯誤",
  ERR_DEPRECATED: "已棄用",
  ERR_BAD_RESPONSE: "回應錯誤",
  ERR_BAD_REQUEST: "請求錯誤",
  ERR_NOT_SUPPORT: "不支持",
  ERR_INVALID_URL: "無效的 URL",
  ERR_CANCELED: "請求已取消",
  ECONNABORTED: "請求超時或被中止",
  ETIMEDOUT: "請求超時",
  ERR_EMAIL_EXIST: "信箱已存在",
  E_INVALID_ID: "無效ID",
  E_GET_DEV_GROUP_DETAIL_ERR: "獲取設備詳情失敗",
  E_USER_NOT_FOUNT: "未找到該用戶",
  E_DEVICE_VNC_OFFLINE: "設備VNC已離線",
  E_MISSING_AUTH_HEADER: "TOKEN請求頭丟失",
  E_TEMPLATE_CAN_NOT_DEL_WITH_BIND_DEV: "該模板已綁定設備",
  E_INVALID_PASSWD: "密碼錯誤",
  E_INPUT_PWD_ERR: "密碼輸入錯誤",
  E_INVALID_OR_EXPIRED_TOKEN: "登入已逾期，請重新登入!",
  E_USER_NOT_FOUND: "用戶不存在",
  ERR_PHONE_NOT_VERIFY: "驗證碼錯誤",
  ERR_ACCOUNT_EXIST: "帳號已存在",
  ERR_EMAIL_NOT_ACTIVE: "信箱未啟動",
  E_BILLING_TAG_RECORD_FOUND: "卡片號碼或密碼錯誤，請檢查您的卡片號碼或密碼",
  E_CLOUD_CARD_PWD_ERR: "卡片號碼或密碼錯誤，請檢查您的卡片號碼或密碼",
  E_BILLING_REMOTE_ACCESS_RECORD_FOUND: "卡片號碼或密碼錯誤，請檢查您的卡片號碼或密碼",
  E_CLOUD_CARD_ALREADY_ACTIVATE: "該計費卡已被啟動，請勿重複啟動",
  E_SAFEAREAS_NAME_EXIST: "安全區名稱已存在",
  E_SAFEAREAS_MORE_THAN_LIMIT: "最多可添加20個安全區",
  E_hdd_team_group_name_exist: "團隊中分組不可重複",
  "1001": "認證錯誤",
  "1002": "權限不足",
  "1003": "暫無數據",
  "1004": "密碼錯誤",
  "1005": "資料庫錯誤",
  "1006": "輸入參數錯誤",
  "1007": "伺服器內部異常",
  "1008": "Auth Header缺失",
  "1009": "登入逾期 請重新登入",
  "1010": "用戶未找到",
  "1011": "驗證碼錯誤",
  "1012": "用戶已存在",
  "1013": "郵件發送失敗",
  "1014": "請到註冊信箱啟動您的帳號",
  "1015": "郵件達到限制",
  "1016": "手機號碼未驗證",
  "1017": "需要重新獲取手機驗證碼",
  "1018": "驗證碼發生失敗",
  "1019": "帳號不匹配 (資源的帳號不匹配)",
  "1020": "信箱啟動連結過期 需要重新註冊",
  "1021": "參數錯誤",
  "1022": "驗證碼已過期",
  "1023": "未找到當前用戶端的Session 資訊  重新發送RAND",
  "1024": "請至HMI開啟VNC",
  "1025": "非法 Pin 碼",
  "1026": "非法的 Licnese",
  "1027": "非法的設備分組 ID",
  "1028": "非法的HMI SN",
  "1029": "非法的模板 ID",
  "1030": "模板名稱已存在",
  "1031": "信箱未驗證",
  "1032": "該分組或其子分組存在設備，不可刪除",
  "1033": "模板數量已達上限",
  "1034": "設備名稱已存在",
  "1037": "該模板存在關聯設備，不可刪除",
  "1042": "切換模板時與新模板的類型不匹配",
  "1043": "卡片號碼或密碼錯誤，請檢查您的卡片號碼或密碼",
  "1044": "計費卡的開通時間已過期",
  "1045": "該計費卡已被啟用，請勿重複啟用",
  "1050": "該計費卡已被啟用，請勿重複啟動用",
  "1055": "帳號已註銷",
  "1063": "角色名稱重複",
  "1065": "最多可添加20個安全區",
  "1066": "安全區名稱已存在",
  "1069": "該帳號已加入其他團隊",
  "1071": "該帳號不在當前團隊內",
  "1078": "非當前數據中心賬號",
  "1083": "團隊中分組不可重複",
  "1085": "警報名稱已存在",
  "1086": "專案名稱已存在",
  "1087": "邀請時輸入專案ID不屬於當前客戶",
  "1090": "告警规则至多只能设置300个规则",
  "2001": "解析DIAView Tar包異常",
  "2003": "網路錯誤"
}
