export default {
  common: {
    accountSetting: "Account Settings",
    passwordSetting: "Password Settings",
    pi: "Please input {0}",
    ps: "Please select {0}",
    and: "and",
    account: "Account",
    verificationCode: "Verification Code",
    password: "Password",
    currentPassword: "Old Password",
    goLogin: "Login with existing account?",
    confirm: "Confirmed",
    confirm2: "Confirmed",
    cancel: "Cancel",
    confirmPassword: "Confirm Password",
    confirmSettings: "Confirm Settings",
    newPassowrd: "New Password",
    userName: "User Name",
    dataCenter: "Data Center",
    appDownload: "Application Download",
    mail: "Email",
    europe: "Europe",
    theUnitedStates: "The United States",
    australia: "Australia",
    china: "China",
    india: "India",
    phone: "Mobile",
    len: "The minimal length of {0} is {1}",
    registerSuccessConfirm: "Registration finished, login now?",
    registerSuccessPLogin: "Account created. Please login.",
    confirmDiff: "Inconsistent password inputed",
    newPwdSame: "The new password set cannot be the same as the current password.",
    newPwdDiff: "Inconsistent password inputed.",
    passwordFormatError: "8-16 characters, containing at least two or more numbers, letters or symbols.",
    phoneAndMailFormatError: "The Mobile/Email address inputed is incorrect.",
    phoneFormatError: "The mobile inputed is incorrect.",
    mailFormatError: "The email address inputed is incorrect.",
    successfully: "completed",
    close: "Close",
    operation: "Operation",
    query: "Search",
    pageTotal: "Total:  {0}",
    pageTotal2: "Total:  {0}",
    tiao: "Entry",
    submit: "Submit",
    min: "Please input at least {0} characters",
    max: "Please input less than {0} characters",
    minMaxLimit: "Please input {0} to {1} characters",
    basic: "Basic",
    tag: "TAG",
    device: "Device",
    screen: "Dashboard Screen",
    alarm: "Alarm",
    loginSuccessfully: "Login completed.",
    logoutSuccessfully: "Logout completed.",
    read: "Read",
    write: "Write",
    readWrite: "Read/Write",
    edit: "Edit",
    delete: "Delete",
    tip: "Tips",
    updateSuccessfully: "Update completed!",
    alarmLevel1: "Urgent",
    alarmLevel2: "Low",
    alarmLevel3: "Normal",
    online: "Online",
    offline: "Offline",
    logout: "Logout",
    settings: "Settings",
    colorScheme: "Color Scheme",
    menuScheme: "Menu",
    lightMode: "Day Mode",
    darkMode: "Night Mode",
    light: "Light",
    dark: "Dark",
    operationSuccessfully: "Operation completed.",
    valName: "Variable Name",
    create: "Create",
    yes: "Yes",
    no: "No",
    getUserInfoFaild: "Failed to get the user information, please login again.",
    switchToNew: "Please complete the basic user information",
    switchToNewMsg: "Submitted completed.",
    resetPassword: "Reset password",
    permission: "Privileges",
    roleName: "Role name",
    unbindCurrDevice: "Unbind the device",
    unbindCurrDeviceMemo: "If the device is deleted, the data generated by this device will be permanently removed.",
    changePasswordSuccessfully: "The password is changed, please login again.",
    changeProfileSuccessfully: "The user information is changed successfully.",
    noData: "No data",
    backToLogin: "Back to Login page",
    mailResultMemo1: "Thanks for registering, an activation email has been sent to your email account.",
    mailResultMemo2:
      "Please check the activation email and finish the registration process as instructed within 48 hours.",
    mailResultMemo3: "No activation code received?",
    mailResultMemo4: "Please check the email is moved to the advertisement or junk email folder.",
    mailResultMemo5: "Retry if no email is received.",
    reSend: "Resend",
    activeMail: "activation email",
    moveTo: "Move to...",
    groupPickerMemo1: "Move to any group",
    groupPickerMemo2: "Please select the group",
    deleteTemplate: "Delete the template",
    deleteTemplateConfirm: "Do you really want to remove the template",
    activeResult: "Activation result",
    dataCenterPickerMemo1: "Your device data will be stored in the selected data center, please choose it carefully",
    templateLimit: "Templates reached maximum limit"
  },
  login: {
    loginPlatform: "Login to DIACloud Platform",
    account: "Account",
    password: "Password",
    rememberPassword: "Remember Me",
    phoneAndMailAndUsername: "Mobile/Email",
    readAndAgree: "I've read and accepted",
    serviceAgreement: "Service Agreement",
    privacyAgreement: "Privacy Policy",
    registerNow: "Register now",
    forgotPassword: "Forgot Password",
    login: "Login",
    goLogin: "Login",
    agreeMemo: "Please read and agree to the Service Agreement and Privacy Policy.",
    cookieTips1: "This website uses cookies",
    cookieTips2:
      "We use cookies to improve your experience on your website, we respect your choices and are committed to providing you with a safe browsing experience.",
    reject: "Reject",
    accept: "Accept"
  },
  register: {
    registerWay: "Registeration Options",
    register: "Registeration",
    phoneRegistration: "Register with Mobile",
    mailRegistration: "Register with Email",
    submit: "Submit",
    getVarifyCode: "Send",
    second: "S",
    sendToMuch: "Too many times tried.",
    contactMemo: "Used for messaging devices - receiving channel information, not used for account login"
  },
  dashboard: {
    dashboard: "Data Dashboard",
    alarm: "Alarm",
    collectionEmpty: "My favorites are empty ",
    goto: "Go to",
    download: "download",
    favoriteDevice: "My favorites",
    deviceOverview: "Device Overview",
    hmiScreen: "HMI Screen",
    config: "Dashboard Screen",
    thumbnail: "Thumbnail",
    viewDcreen: "View Dashboard Screen",
    editScreen: "Edit Dashboard Screen",
    configScreen: "Dashboard Screen",
    billingDetail: "Billing Details",
    hmiBind: "Serial Number",
    basicInfo: "Basic Information",
    configUpdate: "Dashboard Screen Update",
    hmiScreenSN: "HMI SN",
    hmiGatewaySN: "HMI Gateway SN",
    deviceName: "Device Name",
    deviceSN: "Device SN",
    deviceModel: "Device Model",
    deviceGroup: "Device Group",
    relateTemplate: "Template",
    deviceAddress: "Device Address",
    remark: "Remarks",
    configCreate: "Created",
    configModify: "Edited",
    maskSuccessfully: "Added to My Favorites!",
    removeMaskSuccessfully: "Removed from My Favorites",
    editModelMemo: "Changes are saved yet, abandon the changes and switch the tag?",
    notAssociated: "Not associated",
    editScreenMsg1: "Please use DIAWeb Designer to edit the screen.",
    editScreenMsg2: "If DIAWeb Designer is not installed on your computer",
    please: "please"
  },
  device: {
    unBindDevice: "Delete the device",
    unBindDeviceOk: "Delete the device completed",
    deviceManage: "Device Management",
    deviceList: "Device List",
    templateList: "Template List",
    deviceGroup: "Device Groups",
    tunnel: "Tunnel Status",
    groupSameName: "Duplicate group name",
    rename: "Rename",
    addGroup: "Add Group",
    addChildGroup: "Add Sub-Group",
    delete: "Delete",
    deleteSucccessful: "Delete {0} successfully",
    groupName: "Group Name",
    defaultGroup: "Default Group",
    setDefaultGroup: "Set Default Group",
    templateName: "Template Name",
    hmiModel: "HMI Model",
    deviceCount: "Device Number",
    publishTime: "Time Created",
    queryNamePh: "Template Name",
    queryModelPh: "HMI Model",
    addTemplate: "Add Template",
    copyTemplate: "Clone Template",
    deviceName: "Device Name",
    deviceSN: "Device SN",
    description: "Customer Name",
    deviceModel: "Device Model",
    hmiScreenModel: "HMI Model",
    cloneTemplate: "Clone Template",
    remark: "Remarks",
    templateInfo: "Template Information",
    hmiScreen: "HMI Screen",
    fw: "Firmware",
    recipe: "Recipe",
    versionNumber: "Version Number",
    createTime: "Created",
    configurationState: "Dashboard Screen",
    configurationStateCreateTime: "Created",
    configurationStatemodifyTime: "Edited",
    tagName: "TAG Name",
    dataType: "Data Type",
    addrType: "Address Type",
    address: "Address",
    rwType: "Read/Write Type",
    scanTime: "Scan Interval",
    saveHistroy: "Save History Data",
    devTemProName: "Device Name/Template",
    hmiDongleState: "Online Status",
    hmiGraph: "HMI Screen",
    hmiDongleAsync: "Synchronization",
    deviceTag: "Device label",
    tagEmpty1: "No labels yet,",
    createTag: "Create Label",
    editTag: "Edit Label",
    deleteTag: "Delete Label",
    addTag: "Add Label",
    tagNumLimit: "The maximum number of labels is 50",
    checkTagNumLimit: "The maximum number of selected labels is 10",
    createAndSearchTag: "Create/Search",
    addDevice: "Add device",
    syncState1: "Synchronized",
    syncState0: "Synchronizing",
    cloudLastVer: "Cloud Version",
    hmiThisVer: "HMI Version",
    asyncTooltipTitle:
      "Synchronization Status (Please open system update settings on HMI device to conduct synchronization)",
    labelOver: "Label length should be between 1 and 50 characters",
    labelSame: "Label name already exists",
    labelNumLimit: "The number of labels reaches the upper limit of 50",
    deviceChangeGroup: "Change Group",
    changeGroupSuccessfully: "Group changed.",
    addModel: "Add model",
    templateDesign: "Dashboard Screen Design",
    unbindLabel: "Delete Label",
    unbindLabelConfirm:
      "Deleting this label will also delete labels with the same name that have been bound to other devices. Are you sure you want to delete it?",
    templateDesignConfirm: "Whether to edit the template {0} that the device has joined?",

    groupCantRepeat: "Group name cannot be duplicated.",

    projectName: "Project name",
    deleteProject: "Delete project",
    deleteProjectConfirm: "Whether to delete project {0}",
    addProject: "Add project",
    addProject2: "New project",
    projectTips: "This project has expired, please activate the Cloud Application billing card",
    projectDetail: "Project information",
    removeDev: "Remove device",
    removeDevConfirm: "Whether to remove device {0}",
    relatDev: "Associated device",
    viewTag: "View TAG",
    remove: "Remove",
    assignGroupPm: "Please assign device group permissions",
    project: "Project",
    assignProjectPm: "Please assign project list permissions",
    usedTemplate: "Template reference",
    usedProject: "Project reference"
  },
  template: {
    defaultValue: "Default Value",
    description: "Description",
    usedProject: "Used",
    templateMemo: "Template Introduction",
    bindTemplateTip: "Confirm to bind the template",
    templateLockTip1:
      "This template has expired. Please activate the Cloud Application Billing Card and recharge the number of templates",
    templateLockTip2:
      "TIP: After activating the Cloud Application Billing Card, restore from the most recently created template. Please control the total number of your templates to avoid the template you need being unable to restore and use"
  },
  message: {
    messageCenter: "Message Center",
    alarm: "Alarm",
    setting: "Settings",
    system: "Notice",
    alarmMag: "Alarm record",

    billingMsgType1: "Expiration of Remote Access",
    billingMsgType2: "Expiration of Cloud Application",
    billingMsgType3: "Expiration of TAGs",
    billingMsgType4: "Insufficient Device Traffic",
    billingMsgType5: "Insufficient TAG",
    billingMsgType6: "Invitation Notification",
    billingMsgType7: "Cancel invitation reminder ",
    billingMsgType8: "Remove from team notification",
    billingMsgType9: "Main account transfer notification",

    readAllTip: "All messages are marked as read",
    total: "All",
    unread: "New",
    msgType: "Message Type",
    startDate: "Start Time",
    endDate: "End Time",
    readAll: "Mark all as read",
    billing: "Billing",
    billingMsg: "Billing Message",
    notifications: "Notifications",
    warnings: "Warnings",
    noUnread: "No Message",
    noUnreadToday: "No new message is available now",
    goAllMsg: "Read All",
    monthTraffic: "Traffics consumed on this month",
    open: "Enabled",
    close: "Disabled",
    continued: "Continued",
    closeTraffic: "Turn off traffic",
    todayNews: "Today's news (Total: {0})",
    trafficTips1: "Enable the remote access traffics?",
    trafficTips2:
      "Once enabled, the traffic cannot be disabled on this month. Please select your operation with caution.",
    trafficTips3:
      "If you'd like to use Remote Access when there isn't traffic left, please top up the remote access card at 【Billing Management-My Account】",
    trafficTips4: "12G traffics have been consumed on this month, Do you like to keep Remote Access enabled?",
    trafficTips5:
      "Remote Accesss will be disabled by the end of this month, and re-enabled at the beginning of next month.",
    trafficTips6: "To re-enable Remote Access after it's disabled, please go to【Device list-Device Details】",
    trafficTips7: "Remote Access is disabled",
    trafficTips8: "To re-enable it, please go to【Device list-Device Details】"
  },

  system: {
    name: "Name",
    system: "System Management",
    role: "Role Management",
    user: "User Management",
    personCenter: "Personal Settings",
    safeZone: "Security Zone",

    dashboard: "Data Dashboard",
    device: "Device Management",
    billing: "Billing Management",
    message: "Message Center",

    devicelist: "Device List",
    templatelist: "Template List",
    devicegroup: "Device Groups",
    projectlist: "Project List",
    myAccount: "My Account",
    rechargeRecord: "Billing Records",
    msgBilling: "Billing Message",
    relatProject: "Associated project",

    DIACom: "DIACom",
    DIAComMsg: "When enabled, devices can be seen in DIACom",

    "dashboard-view": "Open",

    "devicelist-view": "Open",
    "devicelist-bind": "Bind Device",
    "devicelist-edit": "Edit",
    "devicelist-delete": "Delete",
    "devicelist-hmi": "HMI Screen",
    "devicelist-config": "Dashboard Screen",

    "templatelist-view": "Open",
    "templatelist-add": "Add Template",
    "templatelist-copy": "Clone Template",
    "templatelist-edit": "Add/Edit Template",
    "templatelist-delete": "Delete",
    "templatelist-data-pm": "Set privileges",

    "devicegroup-view": "Open",
    "devicegroup-add": "Add Group",
    "devicegroup-edit": "Edit",
    "devicegroup-delete": "Delete",
    "devicegroup-data-pm": "Set privileges",

    "projectlist-view": "Open",
    "projectlist-add": "新增项目",
    "projectlist-edit": "Edit",
    "projectlist-delete": "Delete",
    "projectlist-data-pm": "Set privileges",

    "myAccount-view": "Open",
    "myAccount-ca-charge": "Cloud Application Top-up",
    "myAccount-ca-allcoate": "Allocate TAGs",
    "myAccount-ra-charge": "Remote Access Top-up",
    "myAccount-ra-allcoate": "Allocate Billing Card",

    "rechargeRecord-view": "Open",

    "msgBilling-view": "Open",

    "user-view": "Open",
    "user-invite": "Invite Users",
    "user-edit": "Change the role",
    "user-delete": "Delete",
    "user-data-pm": "Data Privileges",

    "role-view": "Open",
    "role-add": "Add Role",
    "role-edit": "Edit",
    "role-delete": "Delete",

    "safeZone-view": "Open",
    "safeZone-add": "Add Security Zone",
    "safeZone-edit": "Edit",
    "safeZone-delete": "Delete",

    mainAccount: "Main Account",
    subAccount: "Sub Account",
    teamName: "Team Name",
    team: "Team",
    modifyTeamName: "Please rename the organization at the dropdown bar located at the up-right corner",
    No: "No.",
    roleName: "Role Name",
    cannotDelete: "Failed to delete {0}",
    deleteRoleFirst: "Please remove all the users with this role before remove the role",
    isDelete: "Delete {0}?",
    addsafeZone: "Add Security Zone",
    addRole: "Add Role",
    selectPmSettings: "Operation Privilege",
    enterRoleName: "Please input the role name",
    editRole: "Edit the role",
    inviteAcceptTime: "The time the invitation was accepted",
    multipleSelection: "Multiple choices are acceptable",
    Pmettings: "Privilege settings",
    menuBar: "Menu Bar",
    selectAll: "Select All",
    operationPm: "Operation Privilege",
    dataPm: "Data Privileges",
    safeZoneInUse: "The following roles in the security zone are in use, cannot be removed.",
    inputAlphanumeric: "Please input alphabetic characters or numbers",
    inputAlphaNumericOnly: "Only alphabetic characters or numbers are acceptable",
    editsafeZone: "Edit security zone",
    removeFromTeam: "Delete {0} from the organization?",
    cancelPendingInvitations: "Once deleted, the invitations, sent by the user but not accepted, will be canceled.",
    accountUsername: "Account/User Name",
    inviteUser: "Invite Users",
    emailAccount: "Email Account",
    registerAccount: "The account isn't signed up yet, please sign up first",
    notCurrentDataCenterAccount: "Not the acocunt of the current data center",
    joinedOtherTeams: "The account is a sub-account of another organization",
    inCurrentTeam: "The account is already in the current organization",
    notInCurrentTeam: "The account isn't in the current organization",
    roleChange: "Change the role",
    announcement: "Notice",
    startClearData: "Begin clearing the data",
    acceptAndReLogin: "Accept and login again",
    rejectInvite: "Decline the invitation",
    inviteNotification: "Invitation Notification",
    inviteJoinTeam1: "{0} invites you to join organization {1}",
    dataOnAccount:
      "If there are existing devices, templates, top-up points in your account, it's suggested to join the organization with a new account.",
    unbindDevices:
      "If there are history data in the account, the data should be cleared manually (unbind devices, remove templates) before join a new organization. The data removed is not recoverable.",
    clearDataNotice:
      "The top-up cards and top-up records won't be cleared, but the existing top-up cards won't be migrated to the new organization and there is no visible recharge record.",
    clearDataEffect: "If the data is cleared, the TAGs and traffic quota already allocated will remain valid.",
    successJoinTeam: "After join the organization, the privilege of the account will be set by the main account.",
    reInviteIfRejected: "If needed, the invitation need to be re-initiated after it's declined.",
    unbindDevice: "Unbind the device",
    confirmDeleteDevice1: "Delete device {0}?",
    confirmDeleteDevice2: "Delete device {0}?",
    deleteDeviceWarning:
      "After delete the device, the data of the device will be removed, and will be not recoverable.",
    invitePage: "Invitations",
    nextStep: "Next",
    inviteCancelled1: "The invitation is already canceled.",
    youRejectedInvite: "You have declined the invitation.",
    reInviteIfNeeded: "If you'd like to join the organization, the invitation need to be re-initiated.",
    alreadyInTeam: "You are already in the organization",
    inviteExpired: "The invitation is already invalid.",
    reminder: "Notification",
    viewInvites: "View",
    pendingInvites:
      "There are multiple invitations pending for your processing, please accept and reject them in time. ",
    inviteJoinTeam2: "{0} invites you to join organization {1}, please confirm if you accept the invitation.",
    inviteCancelled2: "{0} has canceled the invitation to join organization {1}",
    removedFromTeam: "You have beem removed from organizaion {0}",
    inviteBecomeMainAccount: "{0} invites you to be the main account of organization {1}, accept?",
    editTeamName: "Edit organization name",
    teamNamePlaceholder: "Organization Name",
    inviteSent: "Invitation sent, pending for acceptance.",
    assignRoleListPm: "Please set privileges of the roles",
    assignPm: "Set privileges",
    contactAdminForPm: "Please ask the administrator to set the privileges",
    close: "Close",
    pendingInvitesAgain:
      "There are multiple invitations pending for your processing, please accept and reject them in time.",
    userAccount: "User Account",
    systemAdministrator: "Administrator",
    allPm: "All",
    platformAllPm: "All privileges in the platform",
    setRolePm: "Please set privileges of the role",
    enterRoleNamePlaceholder: "Please input the role name",
    maxTenCharacters: "10 characters at most",
    assignTemplateListPm: "Please set the privileges of the template",
    addDataPm: "Add data privileges",
    multipleSelection2: "Multiple choices are acceptable",
    removePm: "Remove privileges",
    continueSending: "Go on and sent",
    sendBillingMessages: "Organization Name isn't set, go on and send the Invitation information?",
    sameDataCenterAccounts: "It's only allowed to invite the users under the same data center",

    access: "Open",
    cloudApplicationTopUp: "Cloud Application Top-up",
    remoteAccessTopUp: "Remote Access Top-up",
    noGroups: "No group created",
    contactAdminForPm2: "Please ask the administrator to set the privileges",
    uniqueTeamGroups: "The groups in the organization cann't be duplicate",
    loginWebToAcceptInvite: "Please login the web portal to accept the invitation"
  },
  billing: {
    billingManage: "Billing Management",
    myAccount: "My Account",
    billingOverview: "Billing Overview",
    rechargeRecords: "Billing Records",
    cardType: "Billing Card Type",
    cardNumber: "Card Number",
    cardName: "Billing Card Name",
    cloudAppCard: "Cloud Application",
    remoteAccessCard: "Remote Access",
    activeOp1: "Input the last eight digits of the card number",
    activeOp2: "Scratch off the layer and enter the card code",
    activeOp3: "Turn the card over",
    activating: "Active",
    Outdated: "Outdated",
    noActivated: "Not Activated",
    activeDate: "Activated Date",
    selectActiveDate: "Select Activated Date",
    status: "Status",
    validityDate: "Valid Through",
    TagVal: "Validation of TAGs",
    opAccount: "Operation Account",
    deviceLevel: "Current level of device",
    deviceNowTag: "TAG of Device(Used/Total)",
    allocateDevice: "Allocate Devices",
    allocateDate: "Allocated on",
    selectAllocateDate: "Select Allocated Date",
    allocateLevel: "Level Allocated",
    allocateTagNum: "Allocated TAG Amount",
    allocateTagStatus: "Status of TAGs Allocated",
    allocateTagVal: "Validation of TAGs Allocated",
    allocateCard: "Billing Card",
    sTagNum: "TAGs Left",
    sTemplateNum: "Templates Left",
    traffic: "Traffic",
    trafficCard: "Traffic Card",
    trafficTips: "Traffic usage reminder",
    platformGifts: "Free of Charge",
    rechargeNow: "Top up now",
    total: "Total",
    used: "Used",
    unit: "Unit",
    unit2: "Unit",
    left: "Left",

    tagAllocate: "Allocate TAGs",
    cardAllocate: "Allocate Billing Card",
    allocateTips1: "Please allocate {0} to devices",
    allocateTips2: "Please pay attention to the period of validity of {0}",
    allocateTips3:
      "Once a {0} is assigned to a device, the device cannot be changed throughout the valid period of the {0}.",
    viewAll: "View Detail",
    template: "Template",
    cardUseAla: "Billing Card Usage",
    allocateSuccess: "Allocated successfully",

    num: "Amount",
    notSet: "Level not assigned",
    keepNowLevel: "Keep current device level",
    allocateTips: "TAGS: {0}, Collection Interval:{1} mins",
    level: "Level",
    CollFrequency: "Data Collection Interval",
    Minutes: "Minutes",
    tagNumber: "TAG Amount",
    month: "Month",
    year: "Year",
    deviceDetail: "Device Detail",
    returnTag: "Back to TAG Allocation",
    returnDetail: "Back to TAG Details",
    deviceUnbind: "The device has been unbind from this account",
    notThisAccount: "Top-up for not this account",
    levelDesc: "Level Descriptions",
    allocateTips4: "● Free TAGs offered by the platform can only be assigned to devices with {0} level",
    allocateTips5: "● TAG allocation rules(Data collection interval is determined by the service level of device)",
    allocateTips6:
      "The service level can only be upgraded when it's to be changed. Downgrading of service level is not supported.",
    allocateTips7:
      "If the device binding account is changed, the unexpired {0} assigned to the device by the previously bound account will always follow the device",
    allocateTips8: "Valid traffics will always follow the device",
    optionalRange: "Optional range",
    unallocated: "Not allocated",
    nowAllocateTagNum: "TAGs available",
    avaleBalance: "Total available",
    CurrAllocate: "Allocated this time",
    allCardNum: "Total cards",
    activedCardNum: "Active cards",
    deactivedCardNum: "Outdated cards",
    tagStatus: "TAG status",
    allocatedTagStatus: "Allocated TAG status",
    billcardPackage: "Billing packages",
    billcardActive: "Billing Card activation",
    dataStorage: "Data storage",
    validityPeriod: "Period of validity",
    dataCollFrequency: "Data Collection Interval",
    templateNum: "Template amount",
    pleaseInput: "Please input",
    pleaseSelect: "Please select",
    numTip: "Please input card number",
    passwordTip: "Please input card password",
    InputEnAndNum8: "Please input 8 alphanumeric characters",
    InputEnAndNum16: "Please input 8 alphanumeric characters",
    activedTips: "Kindly reminder",
    activedTips1: "The period of validity is one year after the Billing Card is activated",
    activedTips2: "Please use the service package within the period of validity.",
    cardNum: "Card Number",
    cardPassword: "Card Password",
    InputPassword: "Please input the 8-alphanumeric-character password.",
    InputNum: "Please enter the last 8 digits of the card number.",
    successTip: "Your Billing Card is successfully activated.",
    cardValidityDate: "Validity of Billing Card",
    successTip2: "Please use the service package within the period of validity.",
    failTip: "Failed to activate the Billing Card",
    failReasonTip: "Reason of the failed activation",
    next: "Next",
    activeNow: "Activate now",
    gotIt: "OK",
    actived: "Active",
    deActived: "Outdated",
    tagMessage:
      "There isn't enough TAGs to proceed with the allocation, please use the Cloud Application Billing Card top up now.",
    action: "Top up now",
    know: "OK",
    description1: 'The settings on this page will be applied after click "Top up now".',
    cloudApplication: "Cloud Application",
    remoteAccess: "Remote Access",
    vncTips1:
      "VNC session is active. Regarding network traffic usage, please use it appropriately according to your needs.",
    disabledTemplates: "There are {0} unusable TAG in the template now.",
    tagInfo: "TAG information",
    requirements1: "To make sure TAGs of the template are usable",
    requirements2:
      "please guarantee the number of TAGs allocated to the device is equal or bigger than the number of the device's TAGs.",
    deviceTagCount: "Usable TAGs of the device",
    templateTagCount: "Number of Template's TAG",
    vncTips2: "Viewing the HMI screen remotely will consume network traffics, continue?",
    vncEnd: "VNC session stops.",
    timeLimitReached:
      "You have set a time limit for the VNC session. The time has expired, and the session has been automatically closed.",
    vncTimeSetting: "Max VNC session duration",
    instruction: "When the max duration of a VNC session is reached. The session will be terminated.",
    tagAction: "Max duration",
    trafficTips1: "The network traffics bundled with the device are not enough, please top up if you need to use it.",
    trafficTips2:
      "The current device has insufficient traffic, please avoid using traffic-related transmission services.",
    trafficTips3: 'Please go to "Billing Management -- Billings" to check the statistics of network traffics.',
    trafficTips4: "The device has been deleted",
    trafficTips5: "VNC usage has ended, the device has insufficient traffic",

    selectCardType: "Please select the type of the card",
    trafficTips6: "The unallocated Remote Access card can be allocated at {0}",
    myCount: "My Account-Allocate Billing Card",
    activate: "Activate",
    trafficTips7:
      "The services associated with the card can be used after being allocated to devices, please use the services within the valid period.",
    notAllocate: "Don't allocate temporarily",
    billingCard: "Billing Card",
    trafficTips8: "Not changeable after allocated to devices",
    trafficTips9: "please conduct allocation with caution.",
    trafficTips10: "The traffics that expires first will be used first.",
    confirmAllocationTips: "Allocate services to {0}?",
    confirmAllocation: "Confirm allocation",
    trafficPeriod: "Valid period of Traffics",
    billingCardTraffic: "Traffics of the billing card",
    trafficTotal: "Total traffics",
    trafficUsed: "Traffics used",
    trafficLeft: "Traffics left",
    trafficStatus: "The status of traffics left",
    trafficLeftCard: "Traffics left of the card",
    trafficLeftDevice: "Traffics left of the device",
    billingCardUsage: "Billing Card Usage",
    trafficStatistics: "Statistics of valid traffics",
    trafficsLeftValid: "Valid traffics left",
    trafficsLeftTotal: "Total valid traffics",
    selectDateActive: "Select Activated Date",
    selectDateUsedMin: " Select mindate",
    selectDateUsedMax: " Select maxdate",
    DateofUse: "Date",
    trafficUsed2: "Traffics used",
    trafficUsage: "Traffics Usage",

    freeDongle: "Free of Dongle",
    freeHMI: "Free of HMI"
  },

  alarm: {
    devOrAlarmName: "Device name/alarm name",
    alarmName: "Alarm name",
    alarmGroup: "Alarm group",
    alarmLevel: "Alarm level",
    alarmType: "Alarm type",
    tagName: "TAG name",
    alarmValue: "Alarm value",
    alarmRule: "Deviation value",
    alarmContent: "Alarm content",
    alarmEnable: "Alarm switch",
    alarmGroupMange: "Manage alarm groups",
    alarmCondition: "Alarm conditions",
    alarmState: "Alarm status",
    alarmTime: "Alarm time",
    responseTime: "Response time",
    recoveryTime: "Recovery time",
    minor: "Slight",
    mild: "Lighter",
    moderate: "General",
    severe: "Heavier",
    critical: "Serious",
    HighHigh: "Higher",
    High: "High",
    Low: "Low",
    LowLow: "Lower",
    LargeDeviation: "Significant deviation",
    SmallDeviation: "Minor deviation",
    off: "Off",
    on: "On",
    alarm: "Alarm",
    response: "Respond",
    recovery: "Recover",
    selectTag: "Select TAG",
    errTips1: "{0} not filled",
    errTips2: "{0} does not exist",
    errTips3: "The TAG type for alarm type {0} cannot be String and Bool",
    errTips4: "The TAG type for alarm type {0} can only be Bool",
    addAlarm: "New alarm",
    editAlarm: "Modify alarm",
    when: "When",
    state: "Status",
    errRes: "Failure reason",
    import: "Import",
    export: "Export",
    importErr: "Data import failed",
    errMsg1: "Download error report and modify information according to prompts before uploading again",
    downloadErr: "Download error report",
    clickUpload: "Click to upload",
    importRule: "Import rules",
    importMsg: "Please fill in the information according to the template format",
    importMsg2: "Drag the file here, or",
    importMsg3: "Support xls, xlsx format",
    importMsg4: "Up to 300 entries can be imported at a time",
    downloadTem: "Download template",
    importComplate: "Data import completed",
    fileName: "File name",
    dataNum: "Total data",
    importOK: "Successfully imported",
    importTime: "Import time",
    selectToRes: "Check to respond",
    uploadAgain: "Reupload",
    goList: "Return to list",
    selectAlarmType: "Please select alarm type first",
    typeErr: "The uploaded file format is incorrect, please upload an Excel file",
    goAlarmMsg: "View all and handle alarms"
  },

  ERR_FR_TOO_MANY_REDIRECTS: "Too many redirects",
  ERR_BAD_OPTION_VALUE: "Invalid Parameter Value",
  ERR_BAD_OPTION: "Invalid Parameter Value",
  ERR_NETWORK: "Network Error",
  ERR_DEPRECATED: "Deprecated",
  ERR_BAD_RESPONSE: "Bad Response",
  ERR_BAD_REQUEST: "Bad Request",
  ERR_NOT_SUPPORT: "Not supported",
  ERR_INVALID_URL: "Invalid URL",
  ERR_CANCELED: "Request is canceled",
  ECONNABORTED: "Request timeout or canceled",
  ETIMEDOUT: "Request timeout",
  ERR_EMAIL_EXIST: "Email address already exists",
  E_INVALID_ID: "Invalid ID",
  E_GET_DEV_GROUP_DETAIL_ERR: "Failed to get device details",
  E_USER_NOT_FOUNT: "The user is not found",
  E_DEVICE_VNC_OFFLINE: "The VNC service of the device isn't available",
  E_MISSING_AUTH_HEADER: "Header of the request is lost",
  E_TEMPLATE_CAN_NOT_DEL_WITH_BIND_DEV: "Devices are binded with this template",
  E_INVALID_PASSWD: "Incorrect Password",
  E_INPUT_PWD_ERR: "Password Input Error",
  E_INVALID_OR_EXPIRED_TOKEN: "Login has expired, please log in again!",
  E_USER_NOT_FOUND: "User does not exist",
  ERR_PHONE_NOT_VERIFY: "Verification code error",
  ERR_ACCOUNT_EXIST: "Account already exists",
  ERR_EMAIL_NOT_ACTIVE: "Email is not activated",
  E_BILLING_TAG_RECORD_FOUND: "The card number or password is wrong. Please check your card number or password.",
  E_CLOUD_CARD_PWD_ERR: "The card number or password is wrong. Please check your card number or password.",
  E_BILLING_REMOTE_ACCESS_RECORD_FOUND:
    "The card number or password is wrong. Please check your card number or password.",
  E_CLOUD_CARD_ALREADY_ACTIVATE: "The biliing card has been activated, please do not activate it again",
  "1001": "Authentication Error",
  "1002": "Authorization Error",
  "1003": "No Data Found",
  "1004": "Incorrect Password",
  "1005": "Database Error",
  "1006": "Input Parameter Error",
  "1007": "Server Error",
  "1008": "Auth Header Lost",
  "1009": "Authentication Expired",
  "1010": "User Not Found",
  "1011": "Verification Code Error",
  "1012": "User Already Existed",
  "1013": "Failed to send the email",
  "1014": "Please go to the registered email address to activate your account",
  "1015": "Email Limits Reached",
  "1016": "Mobile Phone Not Verified.",
  "1017": "Please verify the mobile phone again.",
  "1018": "Failed to send verification code",
  "1019": "Account Not Matched",
  "1020": "Activation link expired, please register again",
  "1021": "Json format is incorrect.",
  "1022": "Activation code expired",
  "1023": "The current client's session information is not found. Resend RAND",
  "1024": "Please go to HMI to open VNC",
  "1025": "Illegal PIN",
  "1026": "Illegal License",
  "1027": "Illegal group ID",
  "1028": "Illegal HMI SN",
  "1029": "Illegal Template ID",
  "1030": "Name of the template already exists",
  "1031": "Email Not Verified",
  "1032": "The Group or its Sub-Groups exist on devices, so can't be deleted",
  "1033": "The number of templates has reached the upper limit",
  "1034": "Device name already exists",
  "1037": "This template has associated devices and cannot be deleted",
  "1042": "Template type mismatch when switching to a new template",
  "1043": "The card number or password is wrong. Please check your card number or password.",
  "1044": "Billing card activation time has expired",
  "1045": "The biliing card has been activated, please do not activate it again",
  "1050": "The biliing card has been activated, please do not activate it again",
  "1055": "Account has been deleted",
  "1063": "Duplicate role name",
  "1065": "Maximum of 20 security zones can be added",
  "1066": "Security zone name already exists",
  "1069": "The account has joined another team",
  "1071": "The account is not in the current team",
  "1078": "Not the current data center account",
  "1085": "Alarm name already exists",
  "1086": "Project name already exists",
  "1087": "The project ID entered during invitation does not belong to the current user",
  "1083": "Team groups cannot be duplicated",
  "1090": "告警规则至多只能设置300个规则",
  "2001": "Failed to parse DIAView TAR",
  "2003": "Network Error"
}
