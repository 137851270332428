import { RouteRecordRaw } from "vue-router"
import { i18n } from "@/utils/i18n/index"
const t = i18n.global.t
import { getToken } from "@/utils"
import { useUserInfo } from "@/store"
const LAYOUT = () => import("@/layouts/index-left.vue")

export type RowMeta = {
  meta: {
    title: string
    hidden?: boolean
    icon?: string
  }
  children?: Array<RowMeta>
} & RouteRecordRaw

export const whiteRoutes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    meta: { title: t("login.login") },
    beforeEnter: (to, from, next) => {
      //https://hdd.vidagrid.com/#/login?templateid=497
      //https://hdd.vidagrid.com/#/login?forgot=1
      if (getToken() && to.query.templateid) {
        next(`/device/templatelist?templateid=${to.query.templateid}`)
      } else if (getToken() && to.query.projectid) {
        next(`/device/projectlist?projectid=${to.query.projectid}`)
      } else {
        next()
      }
    },
    component: () => import("@/views/login/index.vue")
  },
  {
    path: "/scada",
    name: "scada",
    meta: { title: t("dashboard.config") },
    component: () => import("@/views/scada.vue")
  },
  {
    path: "/register",
    name: "register",
    meta: { title: t("register.register") },
    component: () => import("@/views/register/index.vue")
  },
  {
    path: "/serviceagreement/cn",
    name: "serviceagreementcn",
    meta: { title: t("login.serviceAgreement") },
    component: () => import("@/views/agreement/service/index_cn.vue")
  },
  {
    path: "/activeresult",
    name: "activeResult",
    meta: { title: t("common.activeResult") },
    component: () => import("@/views/activeResult.vue")
  },
  {
    path: "/serviceagreement/cn",
    name: "serviceagreementcn",
    meta: { title: t("login.serviceAgreement") },
    component: () => import("@/views/agreement/service/index_cn.vue")
  },
  {
    path: "/privacyagreement/cn",
    name: "privacyagreementcn",
    meta: { title: t("login.privacyAgreement") },
    component: () => import("@/views/agreement/privacy/index_cn.vue")
  },
  {
    path: "/serviceagreement/en",
    name: "serviceagreementen",
    meta: { title: t("login.serviceAgreement") },
    component: () => import("@/views/agreement/service/index_en.vue")
  },
  {
    path: "/privacyagreement/en",
    name: "privacyagreementen",
    meta: { title: t("login.privacyAgreement") },
    component: () => import("@/views/agreement/privacy/index_en.vue")
  },
  {
    path: "/appdownload",
    name: "appdownload",
    component: () => import("@/views/appdownload/index.vue"),
    meta: { title: t("common.appDownload") }
  }
]
export const constantRoutes: Array<RowMeta> = [
  {
    path: "/",
    name: "index",
    component: LAYOUT,
    redirect: "/system",
    meta: { title: "" },
    children: [
      {
        path: "/system",
        name: "system",
        redirect: "/system/profile",
        meta: { title: t("system.system"), icon: "icon-quanxianguanli" },
        children: [
          {
            path: "profile",
            name: "profile",
            component: () => import("@/views/system/profile/index.vue"),
            meta: { title: t("system.personCenter") }
          }
        ]
      },
      {
        path: "/message",
        name: "message",
        redirect: "/message/alarm",
        meta: { title: t("message.messageCenter"), icon: "icon-xiaoxizhongxin" },
        children: [
          {
            path: "msgAlarm",
            name: "msgAlarm",
            component: () => import("@/views/message/alarm/index.vue"),
            meta: { title: t("message.alarmMag") }
          },
          {
            path: "msgSystem",
            name: "msgSystem",
            component: () => import("@/views/message/system/index.vue"),
            meta: { title: t("message.system") }
          }
        ]
      }
    ]
  }
  // {
  //   path: "/404",
  //   name: "404",
  //   meta: { title: "404" },
  //   component: () => import("@/views/login/index.vue")
  // }
]
export const dynameicRoutes: Array<RowMeta> = [
  {
    path: "/",
    name: "index",
    component: LAYOUT,
    // redirect: "/dashboard",
    redirect: "/system",
    meta: { title: "" },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/index.vue"),
        meta: { title: t("dashboard.dashboard"), icon: "icon-shujukanban" }
      },
      {
        path: "/device",
        name: "device",
        redirect: "/device/devicelist",
        meta: { title: t("device.deviceManage"), icon: "icon-shebeiguanli" },
        children: [
          {
            path: "devicelist",
            name: "devicelist",
            component: () => import("@/views/device/deviceList/index.vue"),
            meta: { title: t("device.deviceList") }
          },
          {
            path: "templatelist",
            name: "templatelist",
            component: () => import("@/views/device/templateList/index.vue"),
            meta: { title: t("device.templateList") }
          },
          {
            path: "devicegroup",
            name: "devicegroup",
            component: () => import("@/views/device/deviceGroup/index.vue"),
            meta: { title: t("device.deviceGroup") }
          },
          {
            path: "projectlist",
            name: "projectlist",
            component: () => import("@/views/device/projectList/index.vue"),
            meta: { title: t("system.projectlist") }
          }
        ]
      },
      {
        path: "/billing",
        name: "billing",
        redirect: "/billing/myAccount",
        meta: { title: t("billing.billingManage"), icon: "icon-jifeiguanli" },
        children: [
          {
            path: "myAccount",
            name: "myAccount",
            component: () => import("@/views/billing/myAccount/index.vue"),
            meta: { title: t("billing.myAccount") }
          },
          {
            path: "rechargeRecord",
            name: "rechargeRecord",
            component: () => import("@/views/billing/rechargeRecord/index.vue"),
            meta: { title: t("billing.rechargeRecords") }
          }
        ]
      },
      {
        path: "/message",
        name: "message",
        redirect: "/message/msgSystem",
        meta: { title: t("message.messageCenter"), icon: "icon-xiaoxizhongxin" },
        children: [
          {
            path: "msgAlarm",
            name: "msgAlarm",
            component: () => import("@/views/message/alarm/index.vue"),
            meta: { title: t("message.alarmMag") }
          },
          {
            path: "msgBilling",
            name: "msgBilling",
            component: () => import("@/views/message/billing/index.vue"),
            meta: { title: t("message.billingMsg") }
          },
          {
            path: "msgSystem",
            name: "msgSystem",
            component: () => import("@/views/message/system/index.vue"),
            meta: { title: t("message.system") }
          }
        ]
      },
      {
        path: "/system",
        name: "system",
        redirect: "/system/profile",
        meta: { title: t("system.system"), icon: "icon-quanxianguanli" },
        children: [
          {
            path: "profile",
            name: "profile",
            component: () => import("@/views/system/profile/index.vue"),
            meta: { title: t("system.personCenter") }
          },
          {
            path: "user",
            name: "user",
            component: () => import("@/views/system/user/index.vue"),
            meta: { title: t("system.user") }
          },
          {
            path: "role",
            name: "role",
            component: () => import("@/views/system/role/index.vue"),
            meta: { title: t("system.role") }
          },

          {
            path: "safeZone",
            name: "safeZone",
            component: () => import("@/views/system/safeZone/index.vue"),
            meta: { title: t("system.safeZone") }
          }
        ]
      }
    ]
  }
]

export const errRoute: RouteRecordRaw = {
  path: "/:pathMatch(.*)*",
  name: "notFound",
  redirect: "/",
  meta: { title: "404" }
}
