import { RenderFunction } from "vue"
import { RouteRecord, RouteRecordRaw } from "vue-router"
export type toDataURLImgType = "image/jpeg" | "image/webp" | "image/png"
function imgOnload(img: HTMLImageElement, imgType: toDataURLImgType = "image/jpeg"): Promise<string> {
  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvasIns = document.createElement("canvas")
      const ctx = canvasIns.getContext("2d")
      canvasIns.height = img.height
      canvasIns.width = img.width
      ctx?.drawImage(img, 0, 0)
      const dataUrl = canvasIns.toDataURL(imgType)
      resolve(dataUrl)
    }
  })
}

/**
 * Get the image and convert it to base 64 format (promisify)
 * @param {string} img url
 * @param {string} imgType
 * @returns {string}
 *
 */
export async function getImg2Base64(url: string, imgType: toDataURLImgType = "image/jpeg"): Promise<string | null> {
  try {
    const img: HTMLImageElement = new Image()
    img.src = url
    img.crossOrigin = "anonymous"
    const dataUrl = await imgOnload(img, imgType)
    return dataUrl
  } catch (err) {
    return null
  }
}

/**
 *
 * @param {String} src
 * @param {Boolean} crossOrigin
 * @returns promise.then
 */
export function createImg(src: string, crossOrigin: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    if (crossOrigin) img.crossOrigin = "*"
    img.onload = () => {
      resolve(img)
    }
    img.onerror = () => {
      reject(false)
    }
  })
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time: any, cFormat?: any) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}"
  let date
  if (typeof time === "object") {
    date = time
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), "/")
      }
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result: any, key: keyof typeof formatObj) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value]
    }
    return value.toString().padStart(2, "0")
  })
  return time_str
}
export const formatIsoTime = (str: string) => {
  const date = new Date(str)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  const hour = String(date.getHours()).padStart(2, "0")
  const minute = String(date.getMinutes()).padStart(2, "0")
  const second = String(date.getSeconds()).padStart(2, "0")
  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`
  return formattedDate
}

// export const renderIcon = (icon: Component): RenderFunction => {
//   return () => {
//     return h(NIcon, null, {
//       default: () => h(icon)
//     })
//   }
// }
export const renderIconfont = (iconClass: string): RenderFunction => {
  return () => {
    return h("i", { class: "iconfont " + iconClass })
  }
}

export const cyclicXorByKey = (buffer: string, key: string) => {
  let index = 0
  let len = 0
  let encryption = ""
  let charCode = 0

  while (len < buffer.length) {
    const charBuffer = buffer.charCodeAt(len)
    const charSanthings = key.charCodeAt(index)
    charCode = charBuffer ^ charSanthings
    encryption += String.fromCharCode(charCode)

    len++
    index++
    if (index == key.length) {
      index = 0
    }
  }
  return encryption
}

export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split("; ")
  console.log(cookies)
  for (let i = 0; i < cookies.length; i++) {
    let parts = cookies[i].split("=")
    if (parts[0] === name) {
      return decodeURIComponent(parts[1])
    }
  }
  return null
}

export const clearAllCookie = () => {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    for (var i = keys.length; i--; ) document.cookie = keys[i] + "=0;expires=" + new Date(0).toUTCString()
  }
}

// obj to url
export const parseQueryStr = (obj: any) => {
  let str = ""
  for (let key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      str += `${key}=${obj[key]}&`
    }
  }
  return str.slice(0, -1)
}

export const calculateInnerBoxSize = (outerWidth: number, outerHeight: number, rate: number) => {
  const outerRatio = outerWidth / outerHeight
  let innerWidth, innerHeight

  if (outerRatio > rate) {
    innerWidth = outerWidth
    innerHeight = innerWidth / rate

    if (innerHeight > outerHeight) {
      innerHeight = outerHeight
      innerWidth = innerHeight * rate
    }
  } else {
    innerHeight = outerHeight
    innerWidth = innerHeight * rate

    if (innerWidth > outerWidth) {
      innerWidth = outerWidth
      innerHeight = innerWidth / rate
    }
  }

  return {
    width: innerWidth,
    height: innerHeight
  }
}

export const getToken = () => {
  return localStorage.getItem("TOKEN")
}
export const getHost = () => {
  return localStorage.getItem("HOST")
}

export const getWssHost = (type: "template" | "project" = "template") => {
  const url = getHost() || ""
  const host = new URL(url).host
  const wssUrl = type == "template" ? `wss://${host}/wslink1/` : `wss://${host}/wslink2/`
  console.log(wssUrl)
  return wssUrl
}

export const getUserInfo = () => {
  const str = localStorage.getItem("USER_INFO")
  if (str && str !== "undefined") {
    return JSON.parse(str)
  } else {
    return undefined
  }
}

export const setUserInfo = (obj: any) => {
  localStorage.setItem("USER_INFO", JSON.stringify(obj))
}
export const removeUserInfo = () => {
  localStorage.removeItem("USER_INFO")
}

export const setToken = (token: string, refreshToken: string) => {
  localStorage.setItem("TOKEN", token)
  localStorage.setItem("REFRESHTOKEN", refreshToken)
  return getToken()
}
export const getAllToken = () => {
  return {
    token: localStorage.getItem("TOKEN"),
    rToken: localStorage.getItem("REFRESHTOKEN")
  }
}
export const removeToken = () => {
  localStorage.removeItem("TOKEN")
}
export const getRouteNameItem = (targetName: string, routeArray: Array<RouteRecordRaw>) => {
  for (const route of routeArray) {
    if (route.name === targetName) {
      return route
    }
    if (route.children && route.children.length > 0) {
      const childResult: any = getRouteNameItem(targetName, route.children)
      if (childResult !== null) {
        return childResult
      }
    }
  }
  return null
}

export const convertCamelToKebab = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()
}
export const trafficConvert = (value: number) => {
  const units = ["B", "KB", "MB", "GB", "TB"]
  let i = 0
  while (value >= 1024) {
    if (i === 4) break
    value /= 1024
    i++
  }
  //最小MB
  if (i < 2) {
    value /= Math.pow(1024, 2 - i)
    i = 2
  }
  return {
    value: value.toFixed(2),
    unit: units[i]
  }
}

export const sumArray = (numbers: number[]): number => {
  return numbers.reduce((acc, curr) => acc + curr, 0)
}

export const arraysEqual = (arr1: any[], arr2: any[]) => {
  return arr1.length === arr2.length && arr1.every((val: any, idx: number) => val === arr2[idx])
}

export const isKong = (data: any) => data === null || data === undefined || data === ""

export async function exportExcel(api: any, params: any, fileName: string) {
  try {
    const tz = (0 - new Date().getTimezoneOffset() / 60).toString()
    params.tz = tz
    let res = await api(params)
    if (res) {
      console.log(res)
      const _fileName = `${fileName}.xlsx`
      const elink = document.createElement("a")
      elink.download = _fileName
      elink.style.display = "none"
      elink.href = URL.createObjectURL(res)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    }
  } catch (error) {
    console.log(error)
  }
}

import * as unicodeProperties from "unicode-properties"

// 标识符起始字符规则
function isIdentifierStart(category: string, char: string, isFirst: boolean): boolean {
  // 根据 Unicode 标准定义
  const isUnicodeStart = [
    "Lu", // Uppercase Letter
    "Ll", // Lowercase Letter
    "Lt", // Titlecase Letter
    "Lo", // Other Letter (含中文)
    "Nl" // Letter Number (例如罗马数字)
  ].includes(category)

  // 首字符允许下划线（但正则已排除）
  return isUnicodeStart || (!isFirst && char === "_")
}

// 标识符延续字符规则
function isIdentifierExtend(category: string, char: string): boolean {
  return (
    [
      "Mn", // Nonspacing Mark
      "Mc", // Spacing Mark
      "Lm", // Modifier Letter
      "Nd" // Decimal Number
    ].includes(category) || char === "_"
  )
}

function isValidIdentifierName(name: string): boolean {
  // 基础正则校验（禁止数字和下划线开头）
  const pattern = /^(?![_0-9])[\p{Script=Han}a-zA-Z0-9_]+$/u
  if (!pattern.test(name)) {
    return false
  }

  if (!name) return false

  let length = 0
  for (let i = 0; i < name.length; i++) {
    const char = name[i]
    const codePoint = char.codePointAt(0)!

    // 获取 Unicode 属性
    const category = unicodeProperties.getCategory(codePoint)
    const isOtherLetter = category === "Lo" // 中文字符属于 Lo (Other Letter)

    // 长度计算
    length += isOtherLetter ? 8 : 1
    if (length > 200) return false

    // 判断字符类型
    const isFirst = i === 0
    const validStart = isIdentifierStart(category, char, isFirst)
    const validExtend = isIdentifierExtend(category, char)

    if (isFirst && !validStart) return false
    if (!isFirst && !(validStart || validExtend)) return false
  }

  return true
}
