import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import { i18n } from "@/utils/i18n/index"
import { getToken } from "@/utils"
import { getOpBelong } from "@/data/permission"
import { whiteRoutes, constantRoutes, dynameicRoutes, errRoute } from "./routerMap"
import { UserInfoType, useUserInfo } from "@/store"
import { cloneDeep } from "lodash-es"
export type RowMeta = {
  meta: {
    title: string
    hidden?: boolean
    icon?: string
  }
  children?: Array<RowMeta>
} & RouteRecordRaw

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...whiteRoutes, ...constantRoutes]
})

const filterAsyncRoutes = (routes: Array<RowMeta>, userInfo: UserInfoType) => {
  const routerData = cloneDeep(routes)
  if (userInfo.is_main) {
    routerData[0].redirect = "/dashboard"
    return routerData
  } else {
    //获取有权限菜单名数组
    const menuArr = getOpBelong(userInfo.permissions.map((item) => item.toString())) || []
    menuArr?.push(["system", "profile", false])
    menuArr?.push(["message", "msgSystem", false])
    menuArr?.push(["message", "msgAlarm", false])

    //过滤一级菜单
    const menus = routerData[0].children!.filter((routeItem) => {
      return menuArr?.find((menuItem) => menuItem[0] == routeItem.name)
    })
    //过滤二级级菜单
    menus?.map((menu) => {
      if (menu.children) {
        menu.children = menu.children.filter((subMenu) => {
          return menuArr?.find((menuItem) => menuItem[1] == subMenu.name)
        })
      }
    })
    const hasDashboard = menuArr.find((item) => item[1] == "dashboard")
    routerData[0].redirect = hasDashboard ? "/dashboard" : "/system"
    routerData[0].children = menus
    return routerData || constantRoutes
  }
}

const whiteNames = whiteRoutes.map((item) => item.name)

router.beforeEach(async (to, from, next) => {
  window.$loading.start()
  const token = getToken()
  const userStore = useUserInfo()

  if (whiteNames.includes(to.name as string)) {
    return next()
  }

  if (!token) {
    return next("/login")
  }

  if (userStore.menuList.length == 0) {
    await userStore.getUserInfo()
    await userStore.getMsgCount()
    console.log(to, from)

    const menus = filterAsyncRoutes(dynameicRoutes, userStore.userInfo as UserInfoType)
    router.removeRoute("index")
    menus.map((route) => {
      router.addRoute(route)
    })
    if (router.hasRoute("notFound")) {
      router.removeRoute("notFound")
    }
    router.addRoute(errRoute)

    userStore.setMenuList(menus)
    const redirectPath = menus[0].redirect as string
    if (from.name == "login") {
      return next({ path: redirectPath, query: to.query, replace: true })
    } else {
      return next({ ...to, replace: true })
    }
  }
  return next()
})

router.afterEach((to) => {
  window.$loading.finish()
  document.title = `${to.meta.title}`
})

router.onError((error) => {
  console.log(error)
})

export default router
