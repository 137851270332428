import { parseQueryStr } from "@/utils"
import { request } from "@/utils/axios"
import type { Dayjs } from "dayjs"
type RangeValue = [Dayjs, Dayjs]

export type pageType = {
  page: number
  pageSize: number
}

export type MsgTodayParams = {
  tz: string
  isRead?: string
  type?: "billing" | "notice"
}
export const getMsgTodayList = (params: MsgTodayParams & pageType) => {
  const queryStrObj = JSON.parse(JSON.stringify(params))
  return request<any>({
    url: `/msg/notify/today?${parseQueryStr(queryStrObj)}`,
    method: "get"
  })
}

export const getMsgCount = (manual = false) => {
  return request<any>({
    url: `/msg/notify/count`,
    method: "get",
    manual
  })
}

export type MsgParams = {
  // tz: string
  isRead: string //1:read 2:all
  startDate: string
  date: RangeValue | null
  endDate: string
  devName?: string
  cardSn?: string
  msgType: string | null
  type?: string
}
export const getMsgList = (params: MsgParams & pageType) => {
  const queryStrObj = JSON.parse(JSON.stringify(params))
  return request<any>({
    url: `/msg/notify?${parseQueryStr(queryStrObj)}`,
    method: "get"
  })
}

export type SetMsgParams = {
  data?: number[]
  type: number
  form: string
  [key: string]: any
}
export const setMsgRead = (data: SetMsgParams) => {
  return request<any>({
    url: `/msg/setread`,
    method: "post",
    data
  })
}

export type TrafficLimitParams = {
  hmi_sn: string
  disable: number
}
export const openTrafficLimit = (data: TrafficLimitParams) => {
  return request<any>({
    url: `/diacom/monthtraffic`,
    method: "post",
    data
  })
}

//检查邀请信息是否有效
type InviteCheckParams = {
  id: number
  groups?: number[]
  templates?: number[]
}
export const inviteCheck = (data: InviteCheckParams) => {
  return request<any>({
    url: `/team/invitation/confirm/check`,
    method: "post",
    data,
    manual: true
  })
}

//接受邀请
export const inviteConfirm = (data: { id: number; agree: boolean }) => {
  return request<any>({
    url: `/team/invitation/confirm`,
    method: "post",
    data
  })
}
//接受邀请
export const hasNewInvite = (params: pageType) => {
  const queryStrObj = JSON.parse(JSON.stringify(params))
  return request<any>({
    url: `/msg/team/invation?${parseQueryStr(queryStrObj)}`,
    method: "get"
  })
}

//告警消息
export type alarmMsgParams = {
  bindId?: number | null
  startDate?: string
  date?: RangeValue | null
  endDate?: string
  recordType: number | null //1:报警 2:应答 4 恢复
  Name?: string //DevOrAlarmName
  alarmLevel?: number | null
  exportType?: "excel"
}
export const getAlarmMsgList = (params: pageType & alarmMsgParams, manual = false) => {
  const queryStrObj = JSON.parse(JSON.stringify(params))
  return request<any>({
    url: `/alarm/template/web/message/list?${parseQueryStr(queryStrObj)}`,
    method: "get",
    manual
  })
}

//应答告警
export const askAlarms = (data: { group_ids: number[] }) => {
  return request<any>({
    url: `/alarm/template/web/AskAlarms`,
    method: "post",
    data
  })
}
