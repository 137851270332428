import { defineStore } from "pinia"
import { getUserInfo as getUserInfoApi } from "@/services"
import { RowMeta } from "@/router/routerMap"
import { userData } from "@/data/common"
import { getMsgCount as getMsgCountApi } from "@/services/message"
export type UserInfoType = typeof userData
export const useUserInfo = defineStore("USER_INFO", () => {
  const userInfo = ref<typeof userData>()
  const menuList = ref([] as RowMeta[])
  const msgData = ref({
    total: { notice: 0, billing: 0, alarm: 0 },
    unread: { notice: 0, billing: 0, alarm: 0 }
  })

  const getUserInfo = async () => {
    if (!userInfo.value) {
      const { data } = await getUserInfoApi()
      userInfo.value = data
    }
  }

  const getMsgCount = async () => {
    const { data, msg } = await getMsgCountApi(true)
    if (!msg) {
      msgData.value.total = data.total
      msgData.value.unread = data.unread
      msgData.value.unread.alarm = data.total.alarm
    }
  }

  const setMenuList = (items: any) => {
    menuList.value = items
  }

  const $reset = () => {
    userInfo.value = undefined
    menuList.value = []
    msgData.value = {
      total: { notice: 0, billing: 0, alarm: 0 },
      unread: { notice: 0, billing: 0, alarm: 0 }
    }
  }

  return { menuList, userInfo, msgData, getUserInfo, getMsgCount, setMenuList, $reset }
})
